.container {

}
.list{
  display: flex;
  justify-items: center;
  justify-content: flex-start;
  position: relative;
}
.listItem {
  flex: 1;
  /* flex-wrap: 0; */
}
.list:hover .right,.list:hover .left{
  opacity: 1;
}
.right,.left{
  opacity: 0;
}
.leftContainer,
.rightContainer {
  display: flex;
  position: absolute;
  height: 100%;
  width: 40px;
}
.rightContainer {
  right: 0;
}
.leftContainer {
  left: 0;
}
.left, 
.right {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0 0 20px 0 #EEEBF8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  left: 0;
  margin: auto 0;
}
.right {
  transform: rotate(180deg);
}

@media screen and (max-width: 960px){
  .container {
    width: 100%;
    overflow: auto;
    -ms-overflow-style:none;
    scrollbar-width: none;
  }
  .list{
    float:left;
    overflow: auto;
    -ms-overflow-style:none;
    scrollbar-width: none;
  }
  .container::-webkit-scrollbar,
  .list::-webkit-scrollbar {
    display: none;
  }
  .left,.right{
    display: none;
  }
}
