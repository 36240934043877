.kiki-ranger-picker.ant-picker-dropdown-range {
    padding: 0;
}
.kiki-ranger-picker .ant-picker-range-arrow {
    display: none;
}
.kiki-ranger-picker .ant-picker-panel-container {
    border-radius: 12px;
    width: 568px;
    height: 340px;
    margin-top: -2px;
}
.kiki-ranger-picker .ant-picker-panels {
    width: 568px;
    margin-bottom: 12px;
}
.kiki-ranger-picker .ant-picker-panels .ant-picker-panel {
    padding-top: 12px;
    width: 284px;
    border: none;

}
.kiki-ranger-picker .ant-picker-panels .ant-picker-panel:first-child {
    padding-left: 18px;
    margin-right: 14px;
}
.kiki-ranger-picker .ant-picker-header {
    padding: 0;
    width: 252px;
    border: none;
}
.kiki-ranger-picker .ant-picker-panels .ant-picker-panel:first-child .ant-picker-header {
  padding-left: 36px;
}
.kiki-ranger-picker .ant-picker-panels .ant-picker-panel:last-child .ant-picker-header {
  padding-right: 36px;
}
.kiki-ranger-picker .ant-picker-panels .ant-picker-body {
    padding: 0;
}
.kiki-ranger-picker .ant-picker-date-panel .ant-picker-content th,
.kiki-ranger-picker .ant-picker-date-panel .ant-picker-content td
{
    width: 36px;
    height: 30px;
}
.kiki-ranger-picker .ant-picker-footer {
    width: 100%;
    padding: 0 24px;
}
.kiki-ranger-picker .ant-picker-ranges {
    border-top: 1px solid rgba(0, 0, 0, 0.09);
    padding: 14px 0;
}
.kiki-ranger-picker .ant-picker-header-view button,
.kiki-ranger-picker .ant-picker-date-panel .ant-picker-content td .ant-picker-cell-inner,
.kiki-ranger-picker .ant-picker-ranges .ant-picker-preset > .ant-tag {
  font-family: DINPro-Regular;
}
.kiki-ranger-picker .ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
    width: 76px;
    height: 32px;
    line-height: 28px;
    color: #696F7F;
    font-size: 14px;
    text-align: center;
    background: #fff;
    border: 2px solid #F6F8FB;
    border-radius: 8px;
    margin-right: 20px;
    cursor: pointer;
}
.kiki-ranger-picker .ant-picker-ranges .ant-picker-preset > .ant-tag-blue:hover {
    color: #6F40EE;
    background: rgba(111, 64, 238, 0.1);
    border: 2px solid rgba(111, 64, 238, 0);
}
.kiki-ranger-picker .ant-picker-cell {
    padding: 0;
}
.kiki-ranger-picker .ant-picker-cell .ant-picker-cell-inner {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
}
.kiki-ranger-picker tr>th:first-child,
.kiki-ranger-picker tr>td:first-child .ant-picker-cell-inner 
{
  text-align: start;
  padding-left: 8px;
}

.kiki-ranger-picker tr>th:last-child,
.kiki-ranger-picker tr>td:last-child .ant-picker-cell-inner
{
  text-align: end;
  padding-right: 8px;
}

.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner 
{
    height: 28px;
    line-height: 28px;
    top: 1px;
    color: #fff;
    background-color: #6F40EE;
}
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
    border-radius: 4px 0 0 4px;
}
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
    border-radius: 0 4px 4px 0;
}
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    height: 28px;
    background-color: rgba(111, 64, 238, 0.06);
}
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before
{
    height: 28px;
    background-color: rgba(111, 64, 238, 0.06);
}
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #6F40EE;
    border-radius: 4px;
}
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 28px;
  border-top: 1px dashed rgba(111, 64, 238, 0.1);
  border-bottom: 1px dashed rgba(111, 64, 238, 0.1);
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.kiki-ranger-picker tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
.kiki-ranger-picker tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 0px;
  border-left: 1px dashed rgba(111, 64, 238, 0.1);
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.kiki-ranger-picker tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
.kiki-ranger-picker tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.kiki-ranger-picker .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0px;
  border-right: 1px dashed rgba(111, 64, 238, 0.1);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.kiki-ranger-picker .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.kiki-ranger-picker .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  top: 1px;
  height: 28px;
  background: rgba(111, 64, 238, 0.1);
}
.kiki-ranger-picker .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.kiki-ranger-picker .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: rgba(111, 64, 238, 0.1);
}
.kiki-ranger-picker .ant-picker-cell-disabled::before {
  height: 28px;
}
.kiki-ranger-picker .ant-picker-header-view button {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
}
.kiki-ranger-picker .ant-picker-prev-icon,
.kiki-ranger-picker .ant-picker-super-prev-icon,
.kiki-ranger-picker .ant-picker-next-icon,
.kiki-ranger-picker .ant-picker-super-next-icon

{
  color: rgba(0, 0, 0, 0.85);
  top: -2px;
}
.kiki-ranger-picker .ant-picker-prev-icon:hover,
.kiki-ranger-picker .ant-picker-super-prev-icon:hover,
.kiki-ranger-picker .ant-picker-next-icon:hover,
.kiki-ranger-picker .ant-picker-super-next-icon:hover,
.kiki-ranger-picker .ant-picker-header-view button:hover {
  color: #6F40EE;
}
/* popover 👆 */
/* input 👇 */
.kiki-ranger-picker-container {
  position: relative;
  height: 36px;
}
.kiki-ranger-picker-label {
  z-index: 9;
  position: absolute;
  top: 7px;
  left: 10px;
  font-size: 14px;
  color: #696F7F;
  height: 20px;
  line-height: 20px;
  
}
.kiki-ranger-picker.ant-picker {
  display: inline-block;
  width: 100%;
  height: 36px;
  padding-left: 48px;
  padding-right: 10px;
  border-radius: 8px;
  border: 2px solid #F6F8FB;
}
.kiki-ranger-picker .ant-picker-input {
  display: inline-block;
  width: 73px;
}
.kiki-ranger-picker .ant-picker-range-separator {
  display: inline-block;
  padding: 0 3px;
}
.kiki-ranger-picker .ranger-separator {
  display: inline-block;
  width: 8px;
  height: 2px;
  border: 1px solid #979797;
  background-color: #696F7F;
  margin-bottom: 4px;
}
.kiki-ranger-picker .ant-picker-input > input {
  font-family: 'DINPro-Medium';
  font-size: 14px;
  color: #000000;
}
.kiki-ranger-picker .ant-picker-suffix {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 8px;
}
.kiki-ranger-picker .ranger-suffix-icon {
  color: #696F7F;
  font-size: 8px;
}

.ranger-suffix-icon > span {
  transition: 0.2s;
}

.ranger-suffix-icon-up > span {
  transform: rotate(180deg);
}

.kiki-ranger-picker .ant-picker-active-bar {
  display: none;
}
.kiki-ranger-picker.ant-picker-focused {
  box-shadow: none;
  background-color: #F6F8FB;
}
.kiki-ranger-picker .ant-picker-cell-disabled::before {
  background: #f6f8fb;
}
@media screen and (max-width: 960px){
  .kiki-ranger-picker-container {
    position: relative;
    height: 60px;
    padding-top: 24px
  }
  .kiki-ranger-picker-label {
    top: 0px;
    left: 0px;
    font-size: 12px;
  }
  .kiki-ranger-picker.ant-picker {
    padding-left: 12px;
    padding-right: 28px;
    display: flex;
    justify-content: space-between;
  }
  .kiki-ranger-picker .ant-picker-suffix {
    right: 12px;
  }
}