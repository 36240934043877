.kiki-tabs.ant-tabs-top>.ant-tabs-nav::before,
.kiki-tabs.ant-tabs-bottom>.ant-tabs-nav::before,
.kiki-tabs.ant-tabs-top>div>.ant-tabs-nav::before,
.kiki-tabs.ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: none;
}

.kiki-tabs.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.kiki-tabs.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.kiki-tabs.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar,
.kiki-tabs.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar .kiki-tabs .ant-tabs-ink-bar {
  height: 0;
}

/* common 👆 */

/* primary-tabs 👇 */
.kiki-tabs.ant-tabs-top.primary-tabs>.ant-tabs-nav {
  margin-bottom: 0;
}

.kiki-tabs.primary-tabs>.ant-tabs-nav .ant-tabs-tab {
  padding-bottom: 0;
  padding-top: 9px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
/* .kiki-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.kiki-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
      display: none !important;
} */
/* 
.kiki-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  box-shadow: inset -51px 0 26px -26px #fff !important;
}
.kiki-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  box-shadow: inset 51px 0 26px -26px #fff !important;
} */

.kiki-tabs.primary-tabs>.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active::after {
  background-color: #6F40EE;
}

.kiki-tabs.primary-tabs>.ant-tabs-nav .ant-tabs-tab::after {
  content: '';
  top: 40px;
  height: 4px;
  width: 24px;
}

.kiki-tabs.primary-tabs>.ant-tabs-nav .ant-tabs-tab-btn {
  margin-bottom: 9px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: #434B5F;
}

.kiki-tabs.primary-tabs>.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000;
}

/* primary-tabs 👆 */

/* login && register 👇*/
.kiki-tabs.login-tabs.ant-tabs-top>.ant-tabs-nav {
  margin-bottom: 24px;
}

.kiki-tabs.login-tabs.ant-tabs-top .ant-tabs-nav-wrap .ant-tabs-nav-list {
  flex: 1;
  height: 40px;
  background-color: #F6F8FB;
  border: 2px solid #F6F8FB;
  border-radius: 8px;
}
/** 深色模式 */
.dark_tabs.kiki-tabs.login-tabs.ant-tabs-top .ant-tabs-nav-wrap .ant-tabs-nav-list {
  background-color: #252631;
  border-color: #252631;
}
.kiki-tabs.login-tabs.ant-tabs-top .ant-tabs-nav-wrap .ant-tabs-tab {
  flex: 1;
}

.kiki-tabs.login-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  margin: 0 auto;
}
/** 深色模式 */
.dark_tabs.kiki-tabs.login-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: #C8CAD4;
}

.kiki-tabs.login-tabs .ant-tabs-tab+.ant-tabs-tab {
  margin: 0;
}

.kiki-tabs.login-tabs .ant-tabs-tab {
  background-color: #F6F8FB;
  border: 2px solid #F6F8FB;
  border-radius: 8px;
}
/** 深色模式 */
.dark_tabs.kiki-tabs.login-tabs .ant-tabs-tab {
  background-color: #252631;
  border-color: #252631;
}

.kiki-tabs.login-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #6F40EE;
  border: 2px solid #6F40EE;
}

.kiki-tabs.login-tabs .ant-tabs-tab:hover,
.kiki-tabs.login-tabs .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #6F40EE;
}
/** 深色模式 */
.dark_tabs.kiki-tabs.login-tabs .ant-tabs-tab:hover,
.dark_tabs.kiki-tabs.login-tabs .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #fff;
}

.kiki-tabs.login-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.kiki-tabs.login-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
  color: #fff;
}

.trade-drawer-tabs>.ant-tabs-nav {
  margin: 0;
}

.trade-drawer-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab {
  padding: 0 12px;
  min-width: 90px;
  height: 40px;
  border: 2px solid #F6F8FB;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trade-drawer-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-active {
  /* width: 90px; */
  background: rgba(111, 64, 238, 0.1);
  border-radius: 45px;
  border: none;
}

.trade-drawer-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-active>.ant-tabs-tab-btn * {
  color: #6F40EE;
}

.trade-drawer-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab+.ant-tabs-tab {
  margin: 0px 0px 0px 20px
}

.trade-drawer-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab>.ant-tabs-tab-btn .anticon {
  margin: 0px 4px 0px 0px
}

.borderRadius>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-active {
  border-radius: 8px;
}

.borderRadius>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab {
  border-radius: 8px;
}

.market-tabs-two>.ant-tabs-nav {
  margin: 0;
}

.market-tabs-two>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab {
  width: auto;
  height: 32px;
  padding: 0 15px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.market-tabs-two>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-active {
  /* width: 90px; */
  background: #F6F8FB;
  /* border-radius: 45px; */
  border: none;
}

.market-tabs-two>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab>.ant-tabs-tab-btn {
  color: #434B5F;
}

.market-tabs-two>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab-active>.ant-tabs-tab-btn {
  color: #495164;
}

.market-tabs-two>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab+.ant-tabs-tab {
  margin: 0px 0px 0px 20px
}

.market-tabs-two>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list>.ant-tabs-tab>.ant-tabs-tab-btn .anticon {
  margin: 0px 4px 0px 0px
}

html[lang="zh-TC"] .kiki-tabs.primary-tabs .ant-tabs-tab-btn,
html[lang="zh-CN"] .kiki-tabs.primary-tabs .ant-tabs-tab-btn,
html[lang="zh-TC"] .kiki-tabs.primary-tabs .ant-tabs-tab-btn *,
html[lang="zh-CN"] .kiki-tabs.primary-tabs .ant-tabs-tab-btn * {
  font-family: PingFangSC-Medium;
  font-weight: 500;
}

.kiki-tabs.primary-tabs .ant-tabs-tab-btn,
.kiki-tabs.primary-tabs .ant-tabs-tab-btn * {
  font-family: DINPro-Medium;
}

.ant-tabs-content {
  display: flex;
}

.ant-tabs-tabpane {
  flex: none;
  width: 100%;
}

@media (max-width: 960px) {

  /* .kiki-tabs.login-tabs .ant-tabs-tab {
    border-radius: 2px;
  }
  .kiki-tabs.login-tabs .ant-tabs-tab {
    border-radius: 2px;
  } */
  .kiki-tabs.primary-tabs .ant-tabs-tab {
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    border: none;
  }
}